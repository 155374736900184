:root {
    color-scheme: light dark;
    --text-color: #000;
    --gray-text: #4F4F4F;
    --black: #000;
    --footer: #fbf9f9;
    --white: white;
    --background: white;
    --gray: #4c4c4c;
    --semi-white: #F8F9FA;
    --darker-white: #E0E0E0;
    --blue: #1277EB;
    // Fonts
    --font-sans: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --font-mono: 'SFMono-Light', 'SF Mono', 'Ubuntu Mono', Consolas, 'DejaVu Sans Mono', Menlo, monospace;
}

@media (prefers-color-scheme: dark) {
    :root {
        --text-color: #fff;
        --gray-text: #eee;
        --black: #fff;
        --footer: #000;
        --white: #000;
        --background: #1e1e1e;
        --gray: #dbdbdb;
        --darker-white: #888;
        --semi-white: #333;
    }
}

* {
    z-index: 1;
}

img {
    object-fit: contain;
}
body {
    font-family: var(--font-sans);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);
    background: var(--background);
}

html {
    overflow-x: hidden
}

nav {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
    background: var(--semi-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    img {
        margin-left: 10px;
    }

    .links {
        display: flex;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        margin-right: 45px;

        a {
            color: var(--gray-text);
            text-decoration: none;
            padding: 10px 20px;

            &.dl {
                lunar-icon {
                    font-size: 20px;
                }

                background: var(--darker-white);
                border-radius: 50px;
            }
        }
    }
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 135px;

    .title {
        display: flex;
        align-items: center;
        flex-direction: row;

        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 64px;
            line-height: 76px;
            text-align: center;
            padding: 15px;
        }
    }

    .subtitle {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 43px;
            /* identical to box height */
            text-align: center;

            color: #4F4F4F;
        }
    }

    .macbook {
        position: relative;
        top: -20vw;
        z-index: 0;
        width: 100vw;
        height: 95vw;
    }

    &.iOS {
        flex-direction: row-reverse;
        padding-top: 75px;
        flex-wrap: wrap;
        .name {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .iphone {
            height: 90vh;
        }
    }
}

article {
    margin-top: -40vw;
    height: auto;

    &.iOS {
        margin-top: 0;
    }

    .headlines {
        position: relative;
        display: flex;
        flex-direction: column;

        picture {
            display: flex;
            justify-content: center;
            img {
                display: none;
            }
        }
        .section {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            width: 45vw;
            height: 100vh;
            margin-left: 10vw;

            h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 64px;
                line-height: 76px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
            }

            a {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                text-decoration: none;

                color: #2F80ED;
            }
        }
    }
}

.setapp {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 50px 10px;
    setapp-custom-banner {
        width: 100%;
        max-width: 960px;
    }
}

.figure-container {
    height: calc(4*100vh);
    position: absolute;
    top: calc(400px + 70vw);
    right: 20px;
    max-width: 480px;
    width: calc(100vw - 40px);
    z-index: auto;

    &.iOS {
        top: calc(400px + 70vh);
        picture > img {
            top: 20px;
            &.native {
                top: 200px;
            }
        }
    }
    picture > img {
        position: -webkit-sticky;
        position: sticky;
        top: 200px;
        max-width: 480px;
        width: calc(100vw - 40px);
        transition: all 500ms ease-in-out;
    }
}

/* ==========================================================================
   Footer
   ========================================================================== */
footer {
    background-color: var(--background);
    padding: calc(50vh - 128px) 0;
    color: var(--text-color);

    .container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: right;

        .app {
            display: flex;
            justify-content: center;
            align-items: center;

            .price {
                display: inline-block;
                padding: 5px;
            }

            .logo {
                margin: 64px 64px 64px 0;
            }
        }

        .c {
            font-family: sans-serif;
        }

        small {
            font-size: 100%;
            display: block;
            color: var(--gray-text);

            lunar-icon {
                font-size: inherit;
                color: #c62828;
                position: relative;
                font-size: 24px;
            }

            a {
                color: inherit;
            }
        }
    }
}

@media (max-width: 480px) {
    footer .container {
        flex-direction: column;
        text-align: center;

        .logo {
            margin-left: 64px;
        }
    }
}

@media (max-width: 960px) {
    h1 {
        font-size: 36px !important;
        line-height: 42px !important;
    }

    p, a {
        font-size: 14px !important;
        line-height: 19px !important;
    }
    .headlines {
        picture > img {
            display: block !important;
            width: 100vw;
            max-height: 100vh;
        }
        .section{
            width: calc(100vw - 20vw) !important;
        }
    }

    .figure-container {
        display: none;
    }
}